
#jsmind_container[data-v-e75042a0] {
  width: 100%;
  height: 100vh;
}
.custom-tree-node[data-v-e75042a0] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.box-card[data-v-e75042a0] {
  height: 100vh;
}
.el-select .el-input[data-v-e75042a0] {
    width: 130px;
}
.input-with-select .el-input-group__prepend[data-v-e75042a0] {
    background-color: #fff;
}
.st[data-v-e75042a0] .el-input__suffix {
    right: -30px;
}

